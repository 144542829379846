import { baseProductionEnvironment } from '@environments/_base/production.environment';

export const environment = {
  ...baseProductionEnvironment,
  firebase: {
    apiKey: 'AIzaSyDXv_mdoL6bbSWYycoR3__hHPqY2cB2Gj8',
    authDomain: 'pearsports-70c8f.firebaseapp.com',
    databaseURL: 'https://pearsports-70c8f.firebaseio.com',
    projectId: 'pearsports',
    storageBucket: 'pearsports.appspot.com',
    messagingSenderId: '188011390538',
    appId: '1:188011390538:web:55adeed02e9802f4b7a8b3',
    measurementId: 'G-2N376G1JSP',
  },
  pearTVConfig: {
    pearProUrl: 'aaptiv-pro.pearsports.com',
    appHostname: 'tv.pearsports.com',
  },
  datadog: {
    clientToken: 'pubba37f36c70fe2f3995ff50ab4eb2f8ee',
  },
};
