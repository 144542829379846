import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppBrowserModule } from '@app/app.browser.module';
import { datadogLogs } from '@datadog/browser-logs';
import * as Sentry from '@sentry/angular';

import { environment } from './environments/_current/environment';

if (window?.location?.hostname != 'localhost') {
  Sentry.init({
    dsn: environment.sentryDsn,
    environment: environment.serverEnvironment,
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      Sentry.browserTracingIntegration(),
      // Registers the Replay integration,
      // which automatically captures Session Replays
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 0,
    tracePropagationTargets: ['localhost', environment.aaptivServiceUrl],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

  datadogLogs.init({
    clientToken: environment.datadog?.clientToken,
    site: 'datadoghq.com',
    env: environment.serverEnvironment,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: 'all',
  });
}

if (environment.productionMode) {
  enableProdMode();
}

const dataLayer: unknown[] = [];
const initializeGoogleAnalytics = () => {
  const script = document.createElement('script');
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsTrackingId}`;
  script.onload = () => {
    function gtag(...rest: [command: string, config: string]) {
      dataLayer.push(...rest);
    }
    gtag('js', 'gtag/js');
    gtag('config', environment.googleAnalyticsTrackingId);
  };
  document.head.appendChild(script);
};

function bootstrap() {
  platformBrowserDynamic()
    .bootstrapModule(AppBrowserModule)
    .catch((err) => console.error(err));
}

if (document?.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}

initializeGoogleAnalytics();
